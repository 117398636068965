<template>
<!--  <section id="dashboard-ecommerce">-->
<!--    <b-row class="match-height">-->
<!--      &lt;!&ndash;导入渠道客户&ndash;&gt;-->
<!--      <b-col-->
<!--        xl="2"-->
<!--        md="3"-->
<!--      >-->
<!--        <import-company-type />-->
<!--      </b-col>-->

<!--      &lt;!&ndash;采购订单商品导入模板&ndash;&gt;-->
<!--      <b-col-->
<!--              xl="2"-->
<!--              md="3"-->
<!--      >-->
<!--        <b-card-->
<!--                class="card-congratulation-medal"-->
<!--        >-->
<!--          <b-button-->
<!--                  variant="blank"-->
<!--          >-->
<!--            <a  href="https://oss.sbc.xyb2b.com/sbc/purchase_inbound/d8e12c4e89245ae21ca5cebebe315416.xlsx">采购订单商品导入模板 </a>-->

<!--          </b-button>-->
<!--        </b-card>-->
<!--      </b-col>-->

<!--      &lt;!&ndash;采购管理商品物料批量导入模板&ndash;&gt;-->
<!--      <b-col-->
<!--              xl="2"-->
<!--              md="3"-->
<!--      >-->
<!--        <b-card-->
<!--                class="card-congratulation-medal"-->
<!--        >-->
<!--          <b-button-->
<!--                  variant="blank"-->
<!--          >-->
<!--            <a  href="https://oss.sbc.xyb2b.com/sbc/purchase_inbound/d1555c941e14c1e28e557ec5c7b16bf1.xlsx">采购管理商品物料批量导入模板 </a>-->

<!--          </b-button>-->
<!--        </b-card>-->
<!--      </b-col>-->


<!--    </b-row>-->
<!--  </section>-->
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
              no-body
              class="knowledge-base-bg text-center"
              :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">
            Dedicated Source Used on Website
          </h2>
          <b-card-text class="mb-2">
            <span>Popular searches: </span>
            <span class="font-weight-bolder">企业信息, 采购</span>
          </b-card-text>

          <!-- form -->
          <b-form class="kb-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                      id="searchbar"
                      v-model="knowledgeBaseSearchQuery"
                      placeholder="Ask a question...."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>

    <section id="knowledge-base-content">

      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
                v-for="item in filteredKB"
                :key="item.id"
                md="4"
                sm="6"
                class="kb-search-content"
        >
          <b-card
                  class="text-center cursor-pointer"
                  :img-src="item.img"
                  :img-alt="item.img.slice(5)"
                  img-top
                  @click="$router.push({ name: 'pages-export-base-category', params: { category: item.category } })"
          >
            <h4>{{ item.title }}</h4>
            <b-card-text class="mt-1">
              {{ item.desc }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
                v-show="!filteredKB.length"
                cols="12"
                class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </b-col>
      </b-row>
    </section>
  </div>

</template>

<script>
import {  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend,} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import ImportCompanyType from './ImportCompanyType.vue'
import Button from "@/views/components/button/Button";
import Feather from "@/views/ui/feather/Feather";
export default {
  components: {
    Feather,
    Button,
    ImportCompanyType,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,

  },
  data() {
   return{
     knowledgeBaseSearchQuery: '',
     kb: [],
    }
  },
  methods:{
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    },
  },
  created() {
    this.$http.get('/administrator/export/knowledge_base').then(res => {
      console.log("res")
      console.log(res)
      this.kb = res.data })
  },

}
</script>

<style lang="scss">

</style>
