<template>
  <div>
    <b-input
        variant="primary"
        @click="changeData"
    >
      导入渠道客户
    </b-input>
    <b-modal
            id="modal-stock-log"
            centered
            size="xl"
            title="导入数据"
            hide-footer="true"
            ref="remarkModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label-for="attachments_ids"
                  label="相关附件"
                  label-size="sm"
                  class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :object_type="'excel'"
                               @change="onUploadedExcel"
            />
          </b-form-group>
        </b-col>
        <b-col
                cols="12"
                md="12"
                class="demo-inline-spacing d-flex align-items-center justify-content-end"
        >
          <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="exportExcel"
          >
            <span class="align-right">导入</span>
          </b-button>

        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from "@/store";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {useToast} from "vue-toastification/composition";
import {onUnmounted} from "@vue/composition-api";
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions, toDate, toTime} from "@core/utils/filter";
import companyStore from "@/views/apps/company/companyStore";



export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    AttachmentUpload,
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      excelSqlId: '',
    }

  },
  methods: {
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('company')) store.unregisterModule('company')
    })

    const setId = function (params) {
      this.inventory_id = params.inventory_id
    }

    const changeData = function (val) {
      this.$refs['remarkModal'].show()
    }

    const onUploaded = function (id, attachment, result) {
      this.attachments = result
    }

    const onUploadedExcel = function (id, attachment, result) {
      this.excelSqlId = result
    }


    const exportExcel = function () {

      const params = {
        excelSqlId: this.excelSqlId,

      }
      store.dispatch('company/exportExcel', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              toast.success("导入成功")
              refetchData();
            } else {
              toast.error(response.data.data)
            }
          })
    }



    return {

      onUploadedExcel,

      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,

      setId,
      onUploaded,
      exportExcel,
      changeData,
    }
  },

}
</script>
